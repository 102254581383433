







import { ValidationProvider } from 'vee-validate/dist/vee-validate.full.esm'
import { Component, Vue, Prop, Watch } from 'vue-property-decorator'
import Checkbox from '@/components/Controls/Checkbox.vue'
import { DropdownOption } from '@/components/Controls/Dropdown.vue'
import { Services } from 'truemarket-modules/src/services'
import EnumValueType from 'truemarket-modules/src/models/api/enumerations/EnumValueType'

@Component({
  components: {
    ValidationProvider,
    Checkbox
  }
})
export default class PropertyFeatures extends Vue {
  onInput () {
    this.$emit('input', this.propertyFeatures.filter((f) => f.selected).map((f) => f.value))
  }

  @Prop({ required: true })
  public value!: string[]

  @Prop({ required: true })
  public featureType!: 'Commercial' | 'Industrial' | 'Residential' | 'Retail' | 'Rural'

  @Watch('value')
  onValueChanged (newVal: string[]) {
    this.propertyFeatures.forEach((f) => {
      f.selected = newVal.includes(f.value)
    })
  }

  mounted () {
    let enumValueType: EnumValueType | null = null

    switch (this.featureType) {
      case 'Commercial':
        enumValueType = EnumValueType.CommercialPropertyFeatures
        break
      case 'Industrial':
        enumValueType = EnumValueType.IndustrialPropertyFeatures
        break
      case 'Residential':
        enumValueType = EnumValueType.ResidentialPropertyFeatures
        break
      case 'Retail':
        enumValueType = EnumValueType.RetailPropertyFeatures
        break
      case 'Rural':
        enumValueType = EnumValueType.RuralPropertyFeatures
        break
    }

    Services.API.Enumerations.GetEnumValuesForType(enumValueType).then((values) => {
      this.propertyFeatures = values.map(
        (v) => {
          return {
            label: v.Label,
            value: v.ValueId,
            fieldId: v.Value,
            selected: this.value.includes(v.ValueId)
          }
        }
      )
    })
  }

  propertyFeatures: DropdownOption[] = []
}
