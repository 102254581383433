import { render, staticRenderFns } from "./PropertyFeatures.vue?vue&type=template&id=64ce0d46&scoped=true&"
import script from "./PropertyFeatures.vue?vue&type=script&lang=ts&"
export * from "./PropertyFeatures.vue?vue&type=script&lang=ts&"
import style0 from "./PropertyFeatures.vue?vue&type=style&index=0&id=64ce0d46&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "64ce0d46",
  null
  
)

export default component.exports